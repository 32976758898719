import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/cabinet",
    name: "ClientCabinet",
    component: () => import("../views/ClientCabinet.vue"),
    children: [
      {
        path: "/cabinet/contacting-the-mc",
        name: "ContactingMcView",
        component: () => import("../views/cabinet/ContactingMCView.vue"),
        meta: {
          requiresAuth: true,
          requiresConfirmation: true,
        }
      },
      {
        path: "/cabinet/profile",
        name: "ClientProfile",
        component: () => import("../views/cabinet/ClientProfile.vue"),
        meta: {
          requiresAuth: true,
          requiresConfirmation: true,
        },
      },
      {
        path: "documents",
        name: "ClientDocuments",
        component: () => import("../views/cabinet/ClientDocuments.vue"),
        children: [
          {
            path: "FATCA",
            name: "DocumentsFatca",
            component: () =>
              import("../views/cabinet/documentsViews/DocumentsFatca.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "POD-FT",
            name: "DocumentsPOD-FT",
            component: () =>
              import("../views/cabinet/documentsViews/DocumentsPOD.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "KI",
            name: "DocumentsKI",
            component: () =>
              import("../views/cabinet/documentsViews/DocumentsKI.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "personal-accounts",
            name: "DocumentsPersonalAccounts",
            component: () =>
              import(
                "../views/cabinet/documentsViews/DocumentsPersonalAccounts.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "agreements",
            name: "DocumentsAgreements",
            component: () =>
              import("../views/cabinet/documentsViews/DocumentsAgreements.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "personal-data-change",
            name: "DocumentsPersonalDataChange",
            component: () =>
              import(
                "../views/cabinet/documentsViews/DocumentsPersonalDataChange.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "forms",
            name: "Forms",
            component: () =>
              import("../views/cabinet/documentsViews/DocumentsForms.vue"),
            children: [
              {
                path: "fatca",
                name: "FormFatca",
                component: () =>
                  import("../views/cabinet/documentsViews/forms/FormFatca.vue"),
                meta: {
                  requiresAuth: true,
                  requiresConfirmation: true,
                },
              },
              {
                path: "pod-ft",
                name: "FormPodFt",
                component: () =>
                  import("../views/cabinet/documentsViews/forms/FormPodFt.vue"),
                meta: {
                  requiresAuth: true,
                  requiresConfirmation: true,
                },
              },
              {
                path: "ki",
                name: "FormKi",
                component: () =>
                  import("../views/cabinet/documentsViews/forms/FormKi.vue"),
                meta: {
                  requiresAuth: true,
                  requiresConfirmation: true,
                },
              },
              {
                path: "change-personal-data",
                name: "FormChangePersonalData",
                component: () =>
                  import(
                    "../views/cabinet/documentsViews/forms/FormChangePersonalData.vue"
                  ),
                meta: {
                  requiresAuth: true,
                  requiresConfirmation: true,
                },
              },
              {
                path: "registered-person",
                name: "FormRegisteredPerson",
                component: () =>
                  import(
                    "../views/cabinet/documentsViews/forms/FormRegisteredPerson.vue"
                  ),
                meta: {
                  requiresAuth: true,
                  requiresConfirmation: true,
                },
              },
              {
                path: "open-personal-account",
                name: "FormOpenPersonalAccount",
                component: () =>
                  import(
                    "../views/cabinet/documentsViews/forms/FormOpenPersonalAccount.vue"
                  ),
                meta: {
                  requiresAuth: true,
                  requiresConfirmation: true,
                },
              },
            ],
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
        ],
        meta: {
          requiresAuth: true,
          requiresConfirmation: true,
        },
      },
      {
        path: "/cabinet/funds",
        name: "FundsList",
        redirect: "/cabinet/funds/list",
        component: () => import("../views/cabinet/InvestmentPortfolio.vue"),
        meta: {
          requiresAuth: true,
          requiresConfirmation: true,
        },
        children: [
          {
            path: "list",
            name: "Funds",
            component: () => import("../views/cabinet/Funds/Funds.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "fund/:fundId",
            name: "Fund",
            component: () => import("../views/cabinet/Funds/Fund.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
        ],
      },
      {
        path: "/cabinet/investment",
        name: "InvestmentPortfolio",
        redirect: "/cabinet/investment/owned-shares",
        component: () => import("../views/cabinet/InvestmentPortfolio.vue"),
        meta: {
          requiresAuth: true,
          requiresConfirmation: true,
        },
        children: [
          // {
          //   path: "funds",
          //   name: "Funds",
          //   component: () => import("../views/cabinet/Funds/Funds.vue"),
          //   meta: {
          //     requiresAuth: true,
          //     requiresConfirmation: true,
          //   },
          // },
          // {
          //   path: "fund/:fundId",
          //   name: "Fund",
          //   component: () => import("../views/cabinet/Funds/Fund.vue"),
          //   meta: {
          //     requiresAuth: true,
          //     requiresConfirmation: true,
          //   },
          // },
          {
            path: "purchase-of-investment-shares/:fundId",
            name: "BuyInvestmentShares",
            component: () =>
              import("../views/cabinet/Funds/BuyInvestmentShares.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "redemption-of-investment-shares/:fundId",
            name: "SellInvestmentShares",
            component: () =>
              import("../views/cabinet/Funds/SellInvestmentShares.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "owned-shares",
            name: "FundsOwnedShares",
            component: () =>
              import("../views/cabinet/Funds/FundsOwnedShares.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "purchase",
            name: "FundsPurchase",
            component: () => import("../views/cabinet/Funds/PurchaseView.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "redemption",
            name: "FundsRedemption",
            component: () =>
              import("../views/cabinet/Funds/RedemptionView.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
        ],
      },
      {
        path: "/cabinet/messages",
        name: "Messages",
        redirect: "/cabinet/messages/documents",
        component: () => import("../views/cabinet/Messages.vue"),
        meta: {
          requiresAuth: true,
          requiresConfirmation: true,
        },
        children: [
          {
            path: "documents",
            name: "MessagesDocuments",
            component: () => import("../views/cabinet/Messages/Documents.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "pif",
            name: "MessagesContacts",
            component: () => import("../views/cabinet/Messages/Pif.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "info",
            name: "MessagesInfo",
            component: () => import("../views/cabinet/Messages/Info.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
        ],
      },
      {
        path: "/cabinet/about",
        name: "About",
        redirect: "/cabinet/about/us",
        component: () => import("../views/cabinet/About.vue"),
        meta: {
          requiresAuth: true,
          requiresConfirmation: true,
        },
        children: [
          {
            path: "us",
            name: "AboutUs",
            component: () => import("../views/cabinet/About/AboutUs.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "contacts",
            name: "Contacts",
            component: () => import("../views/cabinet/About/Contacts.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
          {
            path: "legal-information",
            name: "LegalInformation",
            component: () =>
              import("../views/cabinet/About/LegalInformation.vue"),
            meta: {
              requiresAuth: true,
              requiresConfirmation: true,
            },
          },
        ],
      },
    ],
    meta: {
      requiresAuth: true,
      requiresConfirmation: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/AuthorizationView.vue"),
  },
  //  working with forgotten password
  {
    path: "/request-password",
    name: "PasswordRequest",
    component: () => import("../views/PasswordRequestView.vue"),
  },
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: () => import("../views/PasswordResetView.vue"),
  },
  //  working with registration
  {
    path: "/registration",
    name: "Registration",
    component: () => import("../views/RegistrationView.vue"),
  },
  {
    path: "/complete-registration",
    name: "CompleteRegistration",
    component: () => import("../views/CompleteRegistrationView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const scrollBehavior = function (to, from, savedPosition) {
  if (to.hash) {
    return { selector: to.hash, behavior: "smooth" };
  } else if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to, 'is auth', store.getters["AuthModule/isAUTHORIZED"], 'is confirmed', store.getters["AuthModule/isCONFIRMED"], 'has passport', store.getters["AuthModule/isHAS_PASSPORT_DATA"])
  // console.log('is user', store.getters["AuthModule/getUSER"])
  if (!store.getters["AuthModule/isAUTHORIZED"]) {
    // if user not authorized redirecting him to login form
    if (to.name?.match("Login") && JSON.stringify(to.query) !== "{}") {
      //if user login from EAIS or has been redirected from e-mail
      store.commit("AuthModule/auth", to.query.auth_key, { root: true });
      if (to.query.new_user)
        store.dispatch("regModule/SET_EMAIL_CONFIRMED", true, { root: true }); //setting up email has been confirmed
      store.dispatch("AuthModule/GET_USER", { root: true }).finally(() => {
        next({
          path: "/complete-registration",
        });
      });
      return;
    }
    // -----------------------------------

    if (to.name?.match("Registration") && JSON.stringify(to.query) !== "{}") {
      store.dispatch("AuthModule/SET_USER", to.query, { root: true }); // creating user model from query data
      store.dispatch(
        "AuthModule/ADD_USER_DATA",
        { passport: { userInfo: to.query } },
        { root: true }
      ); //setting up passport data to created user model
      store.dispatch(
          "AuthModule/UPDATE_USER_DATA",
          { registrationMethod: 3 },
          { root: true }
      );
      store.dispatch("regModule/SET_CONFIRMED_REGISTRATION", true, { root: true }); //setting up registration confirmed for shor registration screen

      // going to target way without query params
      next({
        path: "/registration",
        query: {},
      });
      return;
    }
    //-------------------------------------
    if (
      to.matched.some((record) => record.meta.requiresAuth) &&
      (to.name === null ||
        !to.name?.match("Login") ||
        !to.name?.match("Registration"))
    ) {
      next("/login");
      return;
    }
    //------------------------------------
  } else {
    // if user has been authorized
    // & if some path going to login or registration & user status is minimum 2 then going to personal cabinet
    store.dispatch("AuthModule/GET_USER", { root: true }).then(() => {
      if (
          (!store.getters["AuthModule/isCONFIRMED"] ||
              !store.getters["AuthModule/isHAS_PASSPORT_DATA"]) &&
          to.name !== "CompleteRegistration"
      ) {
        next("/complete-registration");
        return;
      }

      if (
          store.getters["AuthModule/isCONFIRMED"] &&
          store.getters["AuthModule/isHAS_PASSPORT_DATA"] &&
          !to.matched.some((rout) => rout.path === "/cabinet")
      ) {
        next("/cabinet/profile");
        return;
      }
    })
  }

  //------------------------------ don't matter if user has been autorized if hi opening page without query his going to stop
  if (to.path.match("/reset-password") && JSON.stringify(to.query) === "{}") {
    // if user going to reset password without query redirecting him to login page
    next("/login");
    return;
  }
  next();
});

export default router;
