export const BACKEND_ENDPOINTS = {
    login: {method: "POST", url: "user/login"},
    signup: {method: "POST", url: "user/signup"},
    confirmSMS: {method: "POST", url: "user/sms-token"},
    requestSMS: {method: "POST", url: "user/send-sms"},
    resetPasswordRequest: {method: "POST", url: "user/request-password-reset"},
    resetPassword: {method: "POST", url: "user/reset-password"},
    chnagePassword: {method: "POST", url: "user/change-password"},
    addPersonalData: {method: "POST", url: "user/update-info"},
    setKi: {method: "POST", url: "user/set-ki"},
    uploadFiles: {method: "POST", url: "files/upload"},
    deleteFiles: {method: "POST", url: "files/delete"},
    downloadFiles: {method: "POST", url: "files/download"},
    getUser: {method: "GET", url: "user/info"},
    updateUser: {method: "POST", url: "user/update-info"},
    checkLoginPare: {method: "POST", url: "user/check-login"},
    checkRegPare: {method: "POST", url: "user/check-register"},
    autocompleteAddress: {method: "POST", url: "dadata/search/address-query"},
    autocompleteBank: {method: "POST", url: "dadata/search/bank-query"},
    createBankAccount: {method: "POST", url: "bank-account/create"},
    getBankAccounts: {method: "GET", url: "bank-account/list"},
    updateBankAccount: {method: "POST", url: "bank-account/update"},
    getDocuments: {method: "POST", url: "document/list"},
    getAgreements: {method: "POST", url: "document/agreements"},
    createDocument: {method: "POST", url: "document/create"},
    updateDocument: {method: "POST", url: "document/update"},
    getAllDrafts: {method: "GET", url: "document/list-draft"},
    getAllActuals: {method: "GET", url: "document/list-active"},
    getAllUpdates: {method: "POST", url: "document/list-updating"},
    haveAny: {method: "GET", url: "document/list-all"},
    hasEmpty: {method: "GET", url: "document/is-empty"},
    getLSList: {method: "GET", url: "document/ls-list"},
    downloadDocument: {method: "POST", url: "doc/generator/download"},
    getAvalibleFunds: {method: "POST", url: "fonds/list"},
    getFundDetales: {method: "POST", url: "fonds/view"},
    getFundsBuying: {method: "POST", url: "fonds/buy-share-list"},
    getFundsSelling: {method: "POST", url: "fonds/sell-share-list"},
    getOwnedShares: {method: "POST", url: "fonds/invest-shares"},
    getAboutInformation: {method: "POST", url: "content/list"},
    getNotifications: {method: "POST", url: "notification/client/list"},
    sendNotification: {method: "POST", url: "notification/messages/create"},
    searchPassportQuery: {method: "POST", url: "dadata/search/issued-passport-query"},
    searchINN: {method: "POST", url: "fns/search/inn"},
}
