import router from "../../router";
import SocketEvents from "../../models/SocketEvents";
const Sockets = {
    namespaced: true,
    state: {
        isConnected: false,
        socketVisibleSellShares: [],
        socketVisibleChangedPD: false,
    },
    actions: {
        SOCKET_DOCUMENT_CHANGED({commit}) {
            this.$socket.on(SocketEvents.DOCUMENT_CHANGED_BY_ADMINISTRATOR, (payload) => {
                commit("AutoUpdateModule/SET_CHANGED_DOC_ALIAS", payload.data.doc_alias, { root: true });
            });
        },
        SOCKET_USER_CHANGED({commit, dispatch}) {
            this.$socket.on(SocketEvents.USER_CHANGED_BY_ADMINISTRATOR, (payload) => {
                if (payload.data.action) {
                    if (payload.data.action === 'refresh') {
                        window.location.reload();
                        return;
                    }
                    if (payload.data.action === 'load' || payload.data.action === 'load_files') {
                        commit("AutoUpdateModule/CHANGE_FLAG", SocketEvents.USER_CHANGED_BY_ADMINISTRATOR, {root: true});
                        return;
                    }
                    if (payload.data.action === 'load_bank_account') {
                        commit("AutoUpdateModule/CHANGE_FLAG", SocketEvents.USER_CHANGED_BY_ADMINISTRATOR + '_bank_accounts', {root: true});
                        return;
                    }
                    if (payload.data.action === 'logout') {
                        dispatch("AuthModule/LOGOUT", null, { root: true });
                        dispatch("SOCKET_DISCONNECT");
                        window.setTimeout((window.location.href = "/login"), 200);
                        return;
                    }
                }
            });
        },
        SOCKET_FOND_CHANGED({commit}) {
            this.$socket.on(SocketEvents.FOND_CHANGED_BY_ADMINISTRATOR, (payload) => {
                commit("AutoUpdateModule/SET_CHANGED_FOND_ID", payload.data.fond_id, { root: true });
            });
        },
        SOCKET_INFO_SETTING_CHANGED({dispatch}) {
            this.$socket.on(SocketEvents.INFO_SETTINGS_CHANGED_BY_ADMINISTRATOR, () => {
                dispatch("AboutModule/GET_ABUT_INFORMATION", null, { root: true });
            });
        },
        SOCKET_ON_DISCONNECT({commit}) {
            this.$socket.on("disconnectSuccess", (payload) => {
                if (payload.status === 'success'){
                    commit(
                        "mutateState",
                        {
                            to: "Sockets/isConnected",
                            data: false,
                        },
                        { root: true }
                    );
                }
            });
        },
        SOCKET_ON_CONNECT({ commit }) {
            this.$socket.on("connectSuccess", (payload) => {
                commit(
                    "mutateState",
                    {
                        to: "Sockets/isConnected",
                        data: payload.status === "success",
                    },
                    { root: true }
                );
            });
            this.$socket.on("getToken", () => {
                this.$socket.emit("connectUser", {
                    token: sessionStorage.getItem("tAccess") || "",
                });
            });
        },
        SOCKET_NEW_MESSAGES({commit, dispatch}) {
            this.$socket.on("clientNotification", (payload) => {
                commit(
                    "mutateState",
                    {
                        to: "Messages/isNewMessage",
                        data: payload.data,
                    },
                    { root: true }
                );
                dispatch("Messages/GET_NOTIFICATIONS", null, { root: true })
                if (router.currentRoute.name === "Fund" && router.currentRoute.params) dispatch("FundsModule/GET_FUND_DETALES", router.currentRoute.params.fundId, { root: true })
            });
        },
        SOCKET_isVisibleSellShares() {
            this.$socket.emit("isVisibleSellShares");
        },
        SOCKET_isVisibleChanges() {
            this.$socket.emit("isVisibleChanges");
        },
        SOCKET_GET_OUT({ dispatch }) {
            this.$socket.on("getOut", (payload) => {
                if (payload.success) {
                    dispatch("AuthModule/LOGOUT", null, { root: true });
                    dispatch("SOCKET_DISCONNECT");
                    window.setTimeout((window.location.href = "/login"), 200);
                }
            });
        },
        SOCKET_visibleSellShares({ commit }) {
            this.$socket.on("visibleSellShares", (payload) => {
                commit(
                    "mutateState",
                    {
                        to: "Sockets/socketVisibleSellShares",
                        data: payload.data,
                    },
                    { root: true }
                );
            });
        },
        SOCKET_ON_READ({ dispatch }) {
            this.$socket.on("readNotification", (payload) => {
                let result = payload
                if (result.success) {
                    dispatch("Messages/GET_NOTIFICATIONS", null, { root: true })
                }
            });
        },
        SOCKET_visibleChanges({ commit }) {
            this.$socket.on("visibleChanges", (payload) => {
                let result = payload.data[0].visible
                commit(
                    "mutateState",
                    {
                        to: "Sockets/socketVisibleChangedPD",
                        data: result,
                    },
                    { root: true }
                );
            });
        },
        SOCKET_CONNECT() {
            this.$socket.emit("connectUser", {
                token: sessionStorage.getItem("tAccess") || "",
            });
        },
        SOCKET_DISCONNECT() {
            this.$socket.emit("disconnectUser");
        },
        SOCKET_READ(context, payload) {
            this.$socket.emit("read", {
                id: payload
            });
        },
    },
    getters: {
        IS_CONNECTED(state) {
            return state.isConnected;
        },
        /**
         * @return {boolean}
         */
        IS_VISIBE_CHANGES(state) {
            return state.socketVisibleChangedPD;
        },
        /**
         * @return {boolean}
         */
        IS_REDEMPTION(state, getters, rootState, rootGetters) {
            return (
                state.socketVisibleSellShares?.length > 0 ||
                rootGetters["FundsModule/getFundsSellingList"]?.length > 0
            );
        },
        getActiveRedemptionFundsId(state) {
            return state.socketVisibleSellShares?.length > 0 ? state.socketVisibleSellShares.map(fund => fund.id) : []
        }
    },
};
export default Sockets;
