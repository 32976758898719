const mutations = {
  mutateState: (rootState, { to, data }) => {
    if (to.toString().split("/").length > 1) {
      let module = to.toString().split("/")[0];
      let v = to.toString().split("/")[1];
      rootState[module][v] = data
    }
    else rootState[to] = data;
  },
};
export default mutations;
