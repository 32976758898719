import api from "@/api";
import fileDownload from "js-file-download";

const aliases = {
  "qst-fatca": "fatca",
  "qst-pod_ft": "pod-ft",
  "appl-ci": "ki",
  "qst-reg_person": "",
  "appl-open_pers_account": "",
  "appl-change_pers_data": "change-personal-data",
};
const documentsModule = {
  namespaced: true,
  state: () => ({
    documentList: [],
    agreementsList: [],
    drafts: [],
    actuals: [],
    updates: [],
    hasEmpty: null,
    haveAny: false,
    lsList: [],
  }),
  actions: {
    // GET Documents
    async GET_DOCUMENTS({ commit }, payload) {
      let data;
      if (typeof payload === "object") {
        let { alias, fundId } = payload;
        data = await api.getDocuments({
          data: { alias: alias, fond_id: fundId },
        });
      } else {
        data = await api.getDocuments({ data: { alias: payload } });
      }

      return new Promise((resolve) => {
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          resolve(false);
        } else {
          commit(
            "mutateState",
            { to: "DocumentsModule/documentList", data: data.model },
            { root: true }
          );
          resolve(true);
        }
      });
    },

    async GET_LS_LIST({ commit }) {
      const data = await api.getLSList();
      return new Promise((resolve) => {
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          resolve(false);
        } else {
          commit(
            "mutateState",
            { to: "DocumentsModule/lsList", data: data.model },
            { root: true }
          );
          resolve(true);
        }
      });
    },

    SET_LS_LIST({ commit }, payload) {
      commit(
        "mutateState",
        { to: "DocumentsModule/lsList", data: payload.filter(e => !!e.doc_account_open_id) },
        { root: true }
      );
    },

    async HAS_EMPTY({ commit }) {
      const data = await api.hasEmpty();
      commit(
        "mutateState",
        { to: "DocumentsModule/hasEmpty", data: data.success },
        { root: true }
      );
    },
    async HAS_ANY({ commit }) {
      const data = await api.haveAny();
      commit(
        "mutateState",
        { to: "DocumentsModule/haveAny", data: data.success },
        { root: true }
      );
    },

    // get all documents from user
    async GET_ALL_DOCUMENTS({ commit }, payload) {
      const data = await api[
        `getAll${payload.documentsListType[0].toUpperCase()}${payload.documentsListType.slice(
          1
        )}`
      ]();
      return new Promise((resolve) => {
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          resolve(false);
        } else {
          commit(
            "mutateState",
            {
              to: `DocumentsModule/${payload.documentsListType}`,
              data: data.model,
            },
            { root: true }
          );
          resolve(true);
        }
      });
    },

    async GET_AGREEMENTS({ commit }) {
      const data = await api.getAgreements();
      return new Promise((resolve) => {
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          resolve(false);
        } else {
          commit(
            "mutateState",
            { to: "DocumentsModule/agreementsList", data: data.model },
            { root: true }
          );
          resolve(true);
        }
      });
    },
    // CREATE Document
    async CREATE_DOCUMENT({ commit }, payload) {
      return new Promise((resolve) => {
        api
          .createDocument({
            data: { alias: payload.alias, data: payload.data },
          })
          .then((response) => {
            if (!response.success) {
              commit(
                "mutateState",
                { to: "errorModule/error", data: response.errors },
                { root: true }
              );
              resolve(false);
            } else {
              commit(
                "mutateState",
                { to: "errorModule/error", data: {} },
                { root: true }
              );
              let arr = [];
              arr.push(response.model);
              commit(
                "mutateState",
                { to: "DocumentsModule/documentList", data: arr },
                { root: true }
              );
              resolve(true);
            }
          });
      });
    },
    // UPDATE Document
    async UPDATE_DOCUMENT({ commit }, payload) {
      const data = await api.updateDocument({
        data: { id: payload.id, data: payload.data },
      });
      if (!data.success) {
        commit(
          "mutateState",
          { to: "errorModule/error", data: data.errors },
          { root: true }
        );
      } else {
        commit(
          "mutateState",
          { to: "errorModule/error", data: {} },
          { root: true }
        );
        let model = [];
        model.push(data.model);
        commit(
          "mutateState",
          { to: "DocumentsModule/documentList", data: model },
          { root: true }
        );
      }
    },

    //  Upload file
    async UPLOAD_FILE({ commit }, payload) {
      let file = new FormData();
      file.append("file", payload.file);
      file.append("file_type", payload.type);
      file.append("document_id", payload.id);
      return new Promise((resolve) => {
        api.uploadFiles({ data: file }).then((response) => {
          if (response.success) {
            resolve({ success: true, file: response.model });
            commit(
              "mutateState",
              { to: "errorModule/error", data: {} },
              { root: true }
            );
          } else {
            resolve({ success: false });
            commit(
              "mutateState",
              { to: "errorModule/error", data: response.errors },
              { root: true }
            );
          }
        });
      });
    },
    // Delete file

    async DELETE_FILE_FROM_DOCUMENT({ commit }, payload) {
      return new Promise((resolve) => {
        api.deleteFiles({ data: { id: payload.id } }).then((response) => {
          if (response.success) {
            resolve(true);
            commit(
              "mutateState",
              { to: "errorModule/error", data: {} },
              { root: true }
            );
          } else {
            resolve(false);
            commit(
              "mutateState",
              { to: "errorModule/error", data: response.errors },
              { root: true }
            );
          }
        });
      });
    },

    //  download Document
    async DOWNLOAD_DOCUMENT({ commit }, payload) {
      const data = await api.downloadDocument({ data: { id: payload.id } });
      fileDownload(data, payload.name);
      commit(
        "mutateState",
        { to: "errorModule/error", data: {} },
        { root: true }
      );
    },
  },

  // Document statuses
  // 0	"Черновик"
  // 1	"Отправлено"
  // 2	"Отклонено"
  // 3	"Обработано"
  // 4	"Требует актуализации"
  // 9	"Архив"

  getters: {
    hasEMPTY(state) {
      return state.hasEmpty;
    },

    hasANY(state) {
      return state.haveAny;
    },
    getDRAFT(state) {
      let draft = false;
      if (state.documentList.length > 0) {
        draft = state.documentList.find((el) => {
          return (
            (el.status_id === null) |
            (el.status_id === 4) |
            (el.status_id === 2) |
            (el.status_id === 0)
          );
        });
      }
      return draft;
    },
    getACTUAL_DOCUMENT(state) {
      return state.documentList.length > 0
        ? state.documentList.filter((el) => [1, 2, 3, 4].includes(el.status_id))
        : null;
    },
    getAGREEMENTS(state) {
      return state.agreementsList;
    },
    getALL_DRAFTS(state) {
      let drafts = [...state.drafts];
      drafts.map((el) => {
        return (el.alias = aliases[el.alias]);
      });
      return drafts;
    },
    getNEED_UPDATE(state) {
      let updates = [...state.updates];
      updates.map((el) => {
        return (el.alias = aliases[el.alias]);
      });
      return updates;
    },
    getALL_ACTUALS(state) {
      return state.actuals;
    },
    // haveARCHIVED_DOCUMENTS(state) {
    //     let result = false,
    //         archived = state.documentList.map(el => {
    //             return el.status_id === 9
    //         }) || [];
    //     result = state.documentList.length > 0 && archived.length > 0 && state.documentList.length === archived.length;
    //     return result
    // },
    haveACTUAL_DOCUMENTS(state) {
      return state.actuals.length > 0;
    },
    // getNEED_TO_FILL(state, getters, rootState, rootGetters) {
    getNEED_TO_FILL(state) {
      let ankets = [
        {
          alias: "qst-fatca",
          name: "Анкета физического лица (в целях FATCA)",
        },
        {
          alias: "qst-pod_ft",
          name: "Анкета физического лица (ПОД/ФТ)",
        },
      ];
      // TODO open when need to check KI
      // if (rootGetters["AuthModule/getUSER"].ki) {
      //   ankets.push({
      //     alias: "appl-ci",
      //     name: "Заявление о признании физического лица квалифицированным инвестором",
      //   });
      // } else {
      //   if (ankets.find((el) => el.alias === "appl-ci")) {
      //     ankets = ankets.filter((el) => el.alias === "appl-ci");
      //   }
      // }
      ankets = ankets.filter((item) => {
        return !state.actuals.find((el) => item.alias === el.alias);
      });
      ankets.map((el) => {
        return (el.alias = aliases[el.alias]);
      });
      return ankets;
    },
    haveACTUAL_KI(state) {
      return !!state.actuals.find((el) => el.alias === "appl-ci");
    },
    getDOCUMENTS(state) {
      let doc = null;
      if (state.documentList.length > 0) {
        doc = state.documentList.filter((el) => {
          return el.status_id === 9;
        });
      }
      return doc;
    },
    getLS_LIST(state) {
      return state.lsList;
    },
  },
};

export default documentsModule;
