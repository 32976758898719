import api from "@/api";

const bankAccModule = {
  namespaced: true,
  state: () => ({ bankAccounts: [] }),
  actions: {
    // CREATE BANK ACCOUNT
    async CREATE_BANK_ACCOUNT({ commit }, payload) {
      const data = await api.createBankAccount({ data: payload });
      if (!data.success) {
        commit("mutateState", { to: "errorModule/error", data: data.errors }, { root: true });
      } else {
        commit("mutateState", { to: "errorModule/error", data: {} }, { root: true });
        commit("mutateState", { to: "bankAccModule/bankAccounts", data: data.model }, { root: true });
      }
    },
    // GET BANK ACCOUNTS
    async GET_BANK_ACCOUNTS({ commit }) {
      const data = await api.getBankAccounts();
      if (!data.success) {
        commit("mutateState", { to: "errorModule/error", data: data.errors }, { root: true });
      } else {
        commit("mutateState", { to: "bankAccModule/bankAccounts", data: data.model }, { root: true });
      }
    },
    async UPDATE_BANK_ACCOUNT({ commit }, payload) {
      const data = await api.updateBankAccount({ data: payload });
      if (!data.success) {
        commit(
          "mutateState",
          { to: "errorModule/error", data: data.errors },
          { root: true }
        );
      } else {
        commit("mutateState", { to: "bankAccModule/bankAccounts", data: data.model }, { root: true });
      }
    },
  },
  getters: {
    getBANK_ACCOUNTS(state) {
      return state.bankAccounts;
    },
  },
};

export default bankAccModule;
