import api from "@/api";

const smsModule = {
  namespaced: true,
  actions: {
    async REQUEST_SMS({ commit }, payload) {
      if (payload.phone.slice(0, 1) === "+")
        payload.phone = payload.phone.replace(/[^\d]/g, "");
      try {
        const data = await api.requestSMS({ data: payload });
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          return false;
        } else {
          commit("mutateState", { to: "errorModule/error", data: {} }, { root: true });
          return true;
        }
      } catch (error) {
        console.log("error", error);
        return false;
      }
    },
    async CONFIRM_SMS({ commit }, payload) {
      try {
        const data = await api.confirmSMS({ data: payload });
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          return false;
        } else {
          commit("mutateState", { to: "errorModule/error", data: {} }, { root: true });
          return true;
        }
      } catch (error) {
        console.log("error", error, { root: true });
        return false;
      }
    },
  },
};

export default smsModule;
