import api from "@/api";
import fileDownload from "js-file-download";

const regModule = {
  namespaced: true,
  state: () => ({
    isConfirmedRegistration: false,
    isEmailConfirmed: false,
  }),

  actions: {
    MODIFY_USER({ commit }, payload) {
      commit("AuthModule/modify_user", payload, { root: true });
    },
    async CHECK_REG_PARE({ commit }, payload) {
      const data = await api.checkRegPare({ data: payload });
      if (!data.success) {
        commit(
          "mutateState",
          { to: "errorModule/error", data: data.errors },
          { root: true }
        );
      }
      return data.success;
    },
    SET_CONFIRMED_REGISTRATION({ commit }, payload) {
      commit(
        "mutateState",
        {
          to: "regModule/isConfirmedRegistration",
          data: payload,
        },
        { root: true }
      );
    },
    SET_EMAIL_CONFIRMED({ commit }, payload) {
      commit(
        "mutateState",
        {
          to: "regModule/isEmailConfirmed",
          data: payload,
        },
        { root: true }
      );
    },
    SAVE_NEW_USER({ dispatch }, payload) {
      dispatch("AuthModule/SET_USER", payload, { root: true });
    },

    DELETE_USER_DATA() {
      sessionStorage.removeItem("user_model");
    },
    async REG_NEW_USER({ commit }) {
      try {
        const data = await api.signup({
          data: JSON.parse(sessionStorage.getItem("user_model")),
        });
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          return new Promise((resolve) => {
            resolve({ success: false });
          });
        } else {
          commit(
            "mutateState",
            { to: "errorModule/error", data: {} },
            { root: true }
          );
          sessionStorage.setItem("tAccess", data.model.auth_key);
          // commit("set_user", data.model)
          return new Promise((resolve) => {
            resolve({ success: true });
          });
        }
      } catch (error) {
        console.log("error", error, { root: true });
      }
    },
    /**
     * @return {boolean}
     */
    // eslint-disable-next-line no-unused-vars
    async SEND_FILES({ commit, getters, rootState, rootGetters }) {
      // ------ modify files for uploading -------
      // function getPhoto(base64, fileName) {
      //     let base64Parts = base64.split(",");
      //     let fileFormat = base64Parts[0].split(";")[1];
      //     let fileContent = base64Parts[1];
      //     let file = new File([fileContent], fileName, {type: fileFormat});
      //     return file;
      // }
      return new Promise((resolve, reject) => {
        try {
          if (
              rootGetters["AuthModule/getUSER"].snils.scan.file.length > 0 &&
            !rootGetters["AuthModule/getUSER"].snils.scan.file[0]?.id
          ) {
            let snils = new FormData();
            snils.append(
              "file",
                rootGetters["AuthModule/getUSER"].snils.scan.file[0]?.data
            );
            snils.append("changed", "true");

            // console.log(getters.getUSER.snils.scan.file[0], "send-files")
            // snils.append('file', getPhoto(getters.getUSER.snils.scan.file[0]?.data, getters.getUSER.snils.scan.file[0]?.name))
            snils.append(
              "file_type",
                rootGetters["AuthModule/getUSER"].snils.scan.type
            );
            const data1 = api.uploadFiles({ data: snils });
            console.log(data1.success);
            commit("mutateState", { to: "errorModule/error", data: {} }, { root: true });
          }
          if (
              rootGetters["AuthModule/getUSER"].snils.scan.file.length > 0 &&
            !rootGetters["AuthModule/getUSER"].passport.scan.file[0]?.id
          ) {
            let passport = new FormData();
            passport.append(
              "file",
                rootGetters["AuthModule/getUSER"].passport.scan.file[0].data
            );
            passport.append("changed", "true");
            // passport.append('file', getPhoto(getters.getUSER.passport.scan.file[0].data, getters.getUSER.passport.scan.file[0].name))
            passport.append(
              "file_type",
                rootGetters["AuthModule/getUSER"].passport.scan.type
            );
            const data = api.uploadFiles({ data: passport });
            console.log(data.success);
            commit("mutateState", { to: "errorModule/error", data: {} }, { root: true });
          }
          resolve(true);
        } catch (error) {
          console.log("error", error, { root: true });
          reject(error);
        }
      });
    },
    async DOWNLOAD_FILE({ commit }, payload) {
      let data = await api.downloadFiles({ data: { id: payload.id } });
      fileDownload(data, payload.name);
      commit("mutateState", { to: "errorModule/error", data: {} }, { root: true });
    },
    async DELETE_FILE({ commit }, payload) {
      if (payload.file[0]?.id) {
        let data = await api.deleteFiles({ data: { id: payload.file[0].id } });
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
        }
        return data.success;
      }
      commit("mutateState", { to: "AuthModule/delete_users_file", data: payload.type}, {root: true});
      return true;
    },
    /**
     * @return {boolean}
     */
    // eslint-disable-next-line no-unused-vars
    async SEND_USER_DATA({ commit, dispatch, getters, rootState, rootGetters }) {
      let toSendFormat = {
        passport: {
          department: rootGetters["AuthModule/getUSER"].passport.department,
          issue: rootGetters["AuthModule/getUSER"].passport.issue,
          issueDate: rootGetters["AuthModule/getUSER"].passport.issueDate,
          number: rootGetters["AuthModule/getUSER"].passport.number,
        },
        snils: rootGetters["AuthModule/getUSER"].snils.number,
        inn: rootGetters["AuthModule/getUSER"].inn
      };

      // sending string data
      try {
        const data = await api.addPersonalData({ data: toSendFormat });
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          return false;
        } else {
          dispatch("SET_USER", data.model, { root: true });
          commit("mutateState", { to: "errorModule/error", data: {} }, { root: true });
          return true;
        }
      } catch (error) {
        console.log("error", error, { root: true });
      }
    },

    // eslint-disable-next-line no-unused-vars
    async UPDATE_USER_DATA({ commit, dispatch, rootState, rootGetters }) {
      let sendingUser = {
        actualAddress: rootGetters["AuthModule/getUSER"].actualAddress,
        postcodeActualAddress: rootGetters["AuthModule/getUSER"].postcodeactualAddress,
        birthday: rootGetters["AuthModule/getUSER"].birthday,
        birthplace: rootGetters["AuthModule/getUSER"].birthplace,
        email: rootGetters["AuthModule/getUSER"].email,
        inn: rootGetters["AuthModule/getUSER"].inn,
        passport: {
          department: rootGetters["AuthModule/getUSER"].passport.department,
          issue: rootGetters["AuthModule/getUSER"].passport.issue,
          issueDate: rootGetters["AuthModule/getUSER"].passport.issueDate,
          number: rootGetters["AuthModule/getUSER"].passport.number,
        },
        booleanAgreements: { ...rootGetters["AuthModule/getUSER"].booleanAgreements },
        phone: rootGetters["AuthModule/getUSER"].phone,
        registrationAddress: rootGetters["AuthModule/getUSER"].registrationAddress,
        postcodeRegistrationAddress: rootGetters["AuthModule/getUSER"].postcoderegistrationAddress,
        snils: rootGetters["AuthModule/getUSER"].snils.number.replace(/-|\s/g, ""),
        userName: {
          mname: rootGetters["AuthModule/getUSER"].userName.mname,
          name: rootGetters["AuthModule/getUSER"].userName.name,
          sname: rootGetters["AuthModule/getUSER"].userName.sname,
        },
      };
      dispatch("SEND_FILES")
       const data = await api.updateUser({ data: sendingUser })
        if (!data.success) {
          commit(
              "mutateState",
              { to: "errorModule/error", data: data.errors },
              { root: true }
          );
          return new Promise((resolve) => {resolve({success: false})});
        } else {
          commit(
              "mutateState",
              { to: "errorModule/error", data: {} },
              { root: true }
          );
          dispatch("AuthModule/SET_USER", data.model, { root: true });
          return new Promise((resolve) => {resolve({success: true})});
        }
    },
  },
  getters: {
    get_IS_CONFIRMED_REGISTRATION(state) {
      return state.isConfirmedRegistration;
    },
    get_IS_EMAIL_CONFIRMED(state) {
      return state.isEmailConfirmed;
    },
  },
};

export default regModule;
