const AutoUpdateModule = {
    namespaced: true,
    state: () => ({
        documentChangedByAdministrator: 0,
        documentChangedByAdministrator_alias: null,

        userChangedByAdministrator: 0,
        userChangedByAdministrator_bank_accounts: 0,

        fondChangedByAdministrator: 0,
        fondChangedByAdministrator_fond_id: null,
    }),
    mutations:{
        CHANGE_FLAG(state, type) {
            type = type.replace(/[-]/g, '_');
            if (typeof type != 'undefined' && type !== '' && typeof state[type] != 'undefined')
                state[type]++;
        },
        SET_CHANGED_FOND_ID (state, fond_id) {
            state.fondChangedByAdministrator_fond_id = fond_id
            state.fondChangedByAdministrator++;
        },

        SET_CHANGED_DOC_ALIAS (state, alias) {
            state.documentChangedByAdministrator_alias = alias
            state.documentChangedByAdministrator++;
        },
    },
    getters: {
        docChanged(state) {
            return state.documentChangedByAdministrator;
        },
        changedDocAlias(state) {
            return state.documentChangedByAdministrator_alias;
        },

        userChanged(state) {
            return state.userChangedByAdministrator;
        },
        userChangedBankAccounts(state) {
            return state.userChangedByAdministrator_bank_accounts;
        },
        fondChanged(state) {
            return state.fondChangedByAdministrator;
        },
        changedFondId(state) {
            return state.fondChangedByAdministrator_fond_id;
        },
    }
};

export default AutoUpdateModule;