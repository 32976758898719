import api from "@/api";
import UserModel from "@/models/User";

const AuthModule = {
  namespaced: true,
  state: () => ({
    token: sessionStorage.getItem("tAccess") || "",
    login: JSON.parse(sessionStorage.getItem("login") || "{}"),
    user: new UserModel(
      JSON.parse(sessionStorage.getItem("user_model") || "{}")
    ),
    resetPassword: {
      email: "",
      phone: "",
      token: "",
      password: "",
      password_repeat: "",
    },
  }),
  mutations: {
    auth(state, payload) {
      sessionStorage.setItem("tAccess", payload);
      state.token = payload;
    },
    set_user(state, payload) {
      if (!payload) {
        state.user.clear();
      } else {
        if (payload.userName) {
          state.user.loadUser(payload);
        } else state.user.createUser(payload);
      }
      sessionStorage.setItem("user_model", JSON.stringify(state.user));
    },
    modify_user(state, payload) {
      if (payload.doc) {
        state.user[payload.doc][payload.key] = payload.value;
      }
      if (payload.setTo) {
        state.user[`set${payload.setTo}`](payload.data[payload.typeData]);
      }
      if (payload.update) {
        state.user.setProperty({key: payload.key, value: payload.value})
      }
      sessionStorage.setItem("user_model", JSON.stringify(state.user));
    },
    delete_users_file(state, payload) {
      state.user.deleteFiles(payload);
    },
    saveLogin(state, payload) {
      state.login = payload;
    },
    reset(state, payload) {
      payload.email ? (state.resetPassword.email = payload.email) : "";
      payload.phone ? (state.resetPassword.phone = payload.phone) : "";
      payload.token ? (state.resetPassword.token = payload.token) : "";
      payload.password ? (state.resetPassword.password = payload.password) : "";
      payload.password_repeat
        ? (state.resetPassword.password_repeat = payload.password_repeat)
        : "";
    },
    logout(state) {
      state.token = "";
      state.user.clear();
      state.error = [];
      sessionStorage.clear();
    },
  },
  actions: {
    /**
     * @return {boolean}
     */
    async CHECK_LOGIN_PARE({ commit }, payload) {
      const data = await api.checkLoginPare({ data: payload });
      if (!data.success) {
        commit("mutateState", { to: "errorModule/error", data: data.errors }, { root: true });
        return false;
      }
      return data.success;
    },
    SAVE_LOGIN({ commit }, payload) {
      sessionStorage.setItem("login", JSON.stringify(payload));
      commit("saveLogin", payload);
    },
     CLEAR_LOGIN({ commit }) {
      sessionStorage.removeItem("login");
      commit("saveLogin", {});
    },
    SET_USER({ commit }, payload) {
      commit("set_user", payload);
    },
    UPDATE_USER_DATA({ commit }, payload) {
      const key = Object.keys(payload)[0];
      commit("modify_user", {
        update: true,
        key: key,
        value: payload[key],
      });
    },
    ADD_USER_DATA({ commit }, payload) {
      const typeData = Object.keys(payload)[0];
      const setTo = typeData.replace(/( |^)[а-яёa-z]/g, function (x) {
        return x.toUpperCase();
      });
      commit("modify_user", {
        setTo: setTo,
        typeData: typeData,
        data: payload,
      });
    },
    async SET_KI({ dispatch }, payload) {
      const data = await api.setKi({ data: payload });
      dispatch("SET_USER", data.model);
    },
    async GET_USER({ dispatch }) {
      const data = await api.getUser();
      dispatch("SET_USER", data.model);
    },
    async LOGIN({ commit, dispatch, state }, payload) {
      try {
        const data = await api.login({ data: payload });
        if (data.success) {
          commit("auth", data.model.auth_key);
          dispatch("SET_USER", data.model);
          if (state.status < 2) dispatch("SET_CONFIRMED_REGISTRATION", true, { root: true });
          return true
        } else {
          commit("mutateState", { to: "errorModule/error", data: data.errors }, { root: true });
          return false
        }
      } catch (err) {
        console.log("error: ", err.message);
      }
    },
    LOGOUT({ commit }) {
      commit("logout");
    },
  },
  getters: {
    isAUTHORIZED(state) {
      return state.token.length > 0;
    },
    isCONFIRMED(state) {
      return state.user.status >= 2;
    },
    isHAS_PASSPORT_DATA(state) {
      return state.user?.passport?.number?.length > 0;
    },
    isAPROVED(state) {
      return state.user.status === 3;
    },
    getSTATUS(state) {
      return state.user.status;
    },
    getUSER(state) {
      return state.user;
    },
    getLogin(state) {
      return state.login;
    },
    /**
     * @return {string}
     */
    GetUserPosition(state) {
      return state.qualifiedInvestor
        ? "Квалифицированный инвестор"
        : "Инвестор";
    },
    /**
     * @return {string}
     */
    GetUserName(state) {
      return `${state.user?.userName?.sname} ${state.user?.userName?.name}`;
    },
  },
};

export default AuthModule;
