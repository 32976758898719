import api from "@/api";

const aboutInformation = {
  namespaced: true,
  state: () => ({
    aboutUs: {},
    contacts: {},
    legalInformation: {},
    footer: {},
  }),
  actions: {
    // GET Documents
    async GET_ABUT_INFORMATION({ commit }) {
      const data = await api.getAboutInformation();
      return new Promise((resolve) => {
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          resolve(false);
        } else {
          commit(
            "mutateState",
            { to: "AboutModule/aboutUs", data: data.data.about },
            { root: true }
          );
          commit(
            "mutateState",
            { to: "AboutModule/contacts", data: data.data.contact },
            { root: true }
          );
          commit(
            "mutateState",
            {
              to: "AboutModule/legalInformation",
              data: data.data.legal,
            },
            { root: true }
          );
          commit(
            "mutateState",
            { to: "AboutModule/footer", data: data.data.footer },
            { root: true }
          );
          resolve(true);
        }
      });
    },
  },

  getters: {
    getABOUT_US(state) {
      return state.aboutUs;
    },
    getCONTACTS(state) {
      return state.contacts;
    },
    getFOOTER(state) {
      return state.footer;
    },
    getLEGAL_INFORMATION(state) {
      return state.legalInformation;
    },
  },
};

export default aboutInformation;
