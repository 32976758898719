import api from "@/api";

const passwordActionsModule = {
  namespaced: true,
  state: () => ({}),
  actions: {
    // change password from pc
    async CHANGE_PASSWORD({commit}, payload) {
      const data = await api.chnagePassword({ data: payload });
      if (!data.success) {
        commit(
          "mutateState",
          { to: "errorModule/error", data: data.errors },
          { root: true }
        );
      } else {
        commit(
          "mutateState",
          { to: "AuthModule/auth", data: data.auth_key },
          { root: true }
        );
      }
    },
    // change forgotten password
    async REQUIRE_RESET({ commit }, payload) {
      commit(
        "mutateState",
        { to: "AuthModule/reset", data: payload },
        { root: true }
      );
      const data = await api.resetPasswordRequest({ data: payload });
      if (!data.success) {
        commit(
          "mutateState",
          { to: "errorModule/error", data: data.errors },
          { root: true }
        );
      }
      return data.success;
    },
    async RESET_PASSWORD({ commit, dispatch }, payload) {
      commit(
        "mutateState",
        { to: "AuthModule/reset", data: payload },
        { root: true }
      );
      const data = await api.resetPassword({ data: payload });
      return new Promise((resolve) => {
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          resolve(false);
        } else {
          commit(
            "mutateState",
            { to: "AuthModule/auth", data: data.model.auth_key },
            { root: true }
          );
          dispatch(
            "AuthModule/LOGIN",
            { email: data.model.phone, password: payload.password },
            { root: true }
          ).then((a) => {
            resolve (a);
          });
        }
      });
    },
  },
  getters: {},
};

export default passwordActionsModule;
