const errorModule = {
  namespaced: true,
  state: () => ({
    error: {},
  }),
  getters: {
    getERROR(state) {
      if (state.error !== undefined) {
        const keys = Object.keys(state.error);
        let errors = [];
        if (keys && keys.length > 0) {
          keys.forEach((key) => {
            if (typeof state.error[key] === "object") {
              state.error[key].forEach((el) => {
                errors.push(el);
              });
            } else {
              errors.push(state.error[key]);
            }
          });
          return errors[0];
        }
      }
      return [];
    },
  },
};

export default errorModule;
