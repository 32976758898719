import Axios from "axios"
import {BACKEND_ENDPOINTS} from "./api.config"

class ApiClientClass {
    constructor(options = {}) {
        this.defaultHeaders=options.headers || {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
        this.client = options.client || Axios.create({
            baseURL: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '',
            headers: this.defaultHeaders
        });
        // Подготовка запроса для авторизованных пользователей
        this.client.interceptors.request.use (
            config => {
                if (!sessionStorage.getItem('tAccess')) {
                    return config
                }
                let newHeaders;
                if (config.url==="files/download" || config.url==="doc/generator/download") {
                    config.responseType = 'blob',
                    newHeaders = {
                        ...this.defaultHeaders,
                        Authorization: `Bearer ${sessionStorage.getItem('tAccess')}`
                    }
                } else if (config.url==="files/upload") {
                    newHeaders = {
                        "Accept": "application/json",
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${sessionStorage.getItem('tAccess')}`
                    }
                } else {
                    newHeaders = {
                        ...this.defaultHeaders,
                        Authorization: `Bearer ${sessionStorage.getItem('tAccess')}`
                    }
                }
                return {
                    ...config,
                    headers: newHeaders
                }
            },
            e => Promise.reject(e)
        )

    }

    urlFormat(url, args) {
        // return `${process.env.API_URL}/${url}${args.length > 0 ? '!'+args : ''}`
        return `${url}${args.length > 0 ? '!'+args : ''}`
    }
}
export default new Proxy(
    new ApiClientClass(),
    {
        get: function (target, name) {
            if(BACKEND_ENDPOINTS[name] !== undefined) {
                return ({params = {}, data = {}, args = {}} = {}) => {
                    return target.client({
                        method: BACKEND_ENDPOINTS[name].method,
                        url: target.urlFormat(BACKEND_ENDPOINTS[name].url, args),
                        data: data,
                        params: params
                    })
                        .then((serverResponse) => {return serverResponse.data})
                        .catch((error) => {
                            throw new Error('server response error:' + error)
                        })
                }
            } else {
                return target[name]
            }
        }
    }
)
