<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters("AuthModule", ["isAUTHORIZED"]),
    },
    created() {
      window.addEventListener("resize", this.setViewport);
      this.setViewport();
      this.SOCKET_ON_CONNECT()
      this.SOCKET_ON_DISCONNECT();
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.setViewport);
    },
    methods: {
      ...mapActions("Sockets", ["SOCKET_ON_CONNECT", "SOCKET_ON_DISCONNECT"]),
      setViewport: function () {
        let metaTeg = document.querySelector("meta[name='viewport']");
        window.innerWidth <= 600
                ? metaTeg.setAttribute("content", "width=600")
                : metaTeg.setAttribute("content", "width=device-width");
      },
    },
  };
</script>
<style lang="scss">
  body {
    font-family: "NotoSans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }

  p {
    -webkit-text-size-adjust: none;
  }

  body,
  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  h1,
  h2,
  h3 {
    color: $RWM-dark;
  }

  h1 {
    font-weight: 600;
    font-size: 29px;
    line-height: 39px;
  }

  h2 {
    font-weight: bold;
    font-size: 21px;
    line-height: 29px;
  }

  h3 {
    font-weight: bold;
    font-size: 21px;
    line-height: 29px;
  }

  .fond-head {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0;
    text-align: left;
  }

  .anketa {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    color: $RWM-grey;

    &.bold,
    & .bold {
      font-weight: 600;
    }
  }

  .document-link-font {
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }

  button {
    font: bold 24px "NotoSans", sans-serif;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    text-align: center;
    cursor: default;
  }

  .row {
    width: 100%;
    display: flex;
    column-gap: 44px;
    row-gap: 24px;
    flex-direction: column;
    @media screen and (min-width: 1280px) {
      flex-direction: row;
    }
  }

  .col {
    min-width: 250px;
    flex: 1 1 calc((100% / 3) - 2rem);
    @media screen and (min-width: 1280px) {
      flex: 1 1 calc((100% / 3) - 2rem);
      width: 100%;
    }

    &-3 {
      flex: 1 1 100%;
      width: 100%;
      @media screen and (min-width: 1280px) {
        flex: 0 0 calc((100% / 3) - 2rem);
        width: auto;
      }
    }

    &-6 {
      flex: 1 1 calc((100% / 2) - 2rem);
      @media screen and (min-width: 1280px) {
        flex: 0 0 calc((100% / 2) - 2rem);
      }
    }

    &-9 {
      flex: 1 1 calc((100% - 33.333%) - 2rem);
      @media screen and (min-width: 1024px) {
        flex: 0 1 calc(67.777% - 2rem);
      }
    }

    &-12 {
      flex: 1 1 100%;
      width: 100%;
    }
  }
  // base documents & investments class
  .documents {
    flex: 1 1 auto;
    margin-bottom: 16px;
    text-align: left;
    @media screen and (min-width: 961px) {
      padding: 0;
    }

    & .form-container {
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      &__form {
        display: flex;
        column-gap: 32px;
        row-gap: 32px;
        flex-wrap: wrap;
      }

      &__info {
        max-width: 718px;
      }
    }
  }

  /* transitions */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .slide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to,
  .slide-leave {
    max-height: 100vh;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .modal-open {
    overflow: hidden;
  }

  // this for custom elements from back html editor
  .rwm-card-headline-red {
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    color: #DA291C;
  }
  .rwm-card-headline {
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    color: #586770;
  }
  .rwm-subtitle1 {
    font-size: 19px;
    line-height: 26px;
    font-weight: 500;
    color: #586770;
  }
  .rwm-menu1 {
    font-size: 17px;
    line-height: 23px;
    font-weight: bold;
    color: #333F4F;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  .rwm-menu2 {
    font-size: 19px;
    line-height: 26px;
    font-weight: normal;
    color: #333F4F;
  }
  .rwm-body-text-highlighting {
    font-size: 19px;
    line-height: 26px;
    font-weight: bold;
    color: #586770;
  }
  .rwm-alerts {
    font-size: 17px;
    line-height: 23px;
    font-weight: bold;
    color: #586770;
  }
  .rwm-text-alerts-underline {
    font-size: 17px;
    line-height: 23px;
    font-weight: normal;
    color: #DA291C;
    text-decoration-line: underline;
  }
  .rwm-text-alerts {
    font-size: 17px;
    line-height: 23px;
    font-weight: normal;
    color: #DA291C;
    text-decoration-line: none;
  }
  .rwm-text-for-cards {
    font-size: 17px;
    line-height: 23px;
    font-weight: 500;
    color: #586770;
  }
  .rwm-text-fields {
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    color: #586770;
  }
  .rwm-footer-headline {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 46px;
    text-transform: uppercase;
    color: #333F4F;
  }
  .rwm-footer-text {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: #333F4F;
  }
  .rwm-footer-text-small {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25;
    color: #333F4F;
  }

  .fund_name {
    display: none;
    @media screen and (min-width: 961px) {
      display: flex;
    }
  }

  .fund_short_name {
    display: flex;
    @media screen and (min-width: 961px) {
      display: none;
    }
  }

</style>
<style lang="scss">
  #modals-container {
    & .vm--container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .vm--modal {
      top: 0 !important;
      left: 0 !important;
      overflow: initial;
    }
  }
</style>
<style>
  body.modal-open .vm--overlay {
    background: #c1cbd6;
    opacity: 0.8;
  }

  body.modal-open .vm--modal {
    box-shadow: 0px 4px 12px rgba(51, 63, 79, 0.08),
    0px 1px 2px rgba(51, 63, 79, 0.32);
    border-radius: 5px;
    padding: 32px;
    text-align: left;
  }

  .modal__close-btn {
    position: absolute;
    top: 25px;
    right: 32px;
  }

  .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
</style>
