import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/scss/RWMicons.css";
// Modal component
import VModal from "vue-js-modal";
// toasts with styles
import Toast from "vue-toastification";
// plugin for masking fields
import VueMask from "v-mask";
import Maska from 'maska'

// plugin for scrolling page to hash
import VueScrollTo from "vue-scrollto";

import socket from "./modules/module.soket";

Vue.prototype.$socket = socket;

Vue.use(VueScrollTo, {
  container: ".cabinet-wrapper",
  duration: 500,
  easing: "ease",
  offset: -142,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
Vue.use(VueMask);
Vue.use(Maska)
Vue.config.productionTip = false;
Vue.use(VModal);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 5,
  newestOnTop: true,
  timeout: 4000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
