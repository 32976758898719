import Vue from "vue";
import Vuex from "vuex";
import AuthModule from "./modules/auth";
import smsModule from "./modules/sms";
import errorModule from "./modules/error";
import regModule from "./modules/registration";
import passwordActionsModule from "./modules/passwordActions";
import autocompleteModule from "./modules/autocomplete";
import bankAccModule from "./modules/bankAcc";
import DocumentsModule from "./modules/documents";
import FundsModule from "./modules/funds";
import AboutModule from "./modules/aboutInformation";
import Messages from "./modules/messages";
import Sockets from "./modules/sockets";
import AutoUpdateModule from "./modules/autoupdate";

import socket from "../modules/module.soket";
import createWebSocket from "../modules/module.ws";
import mutations from "./mutations/mutations";

Vue.use(Vuex);
const websocket = createWebSocket(socket);

export default new Vuex.Store({
  mutations,
  modules: {
    AuthModule,
    smsModule,
    errorModule,
    regModule,
    passwordActionsModule,
    autocompleteModule,
    bankAccModule,
    DocumentsModule,
    FundsModule,
    AboutModule,
    Messages,
    Sockets,
    AutoUpdateModule,
  },
  plugins: [websocket],
});
