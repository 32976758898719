import api from "@/api";

const autocompleteModule = {
  namespaced: true,
  state: () => ({
    wait: false,
    banksAutoComplete: [],
  }),
  actions: {
    async AUTOCOMPLETE_ADDRESS({ commit, state }, payload) {
      let data;
      if (!state.wait) {
        commit("mutateState", { to: "autocompleteModule/wait", data: true }, { root: true });
        data = await api.autocompleteAddress({ data: payload });
        if (data !== undefined) {
          setTimeout(function () {
            commit("mutateState", { to: "autocompleteModule/wait", data: false }, { root: true });
          }, 1000);
        }
      }

      if (data !== undefined) {
        if (!data.success) {
          commit("mutateState", { to: "errorModule/error", data: data.errors }, { root: true });
        } else {
          return data.result.address;
        }
      }
    },
    async AUTOCOMPLETE_BANK({ commit, state }, payload) {
      let data;
      if (!state.wait) {
        commit("mutateState", { to: "autocompleteModule/wait", data: true }, { root: true });
        data = await api.autocompleteBank({ data: payload });
        if (data !== undefined) {
          setTimeout(function () {
            commit("mutateState", { to: "autocompleteModule/wait", data: false }, { root: true });
          }, 1000);
        }
      }
      if (data !== undefined) {
        if (!data.success) {
          commit("mutateState", { to: "errorModule/error", data: data.errors }, { root: true });
        } else {
          commit("mutateState", {
            to: "autocompleteModule/banksAutoComplete",
            data: data.result.banks,
          }, { root: true });
        }
      }
    },
  },
  getters: {
    GET_BANKS_AUTOCOMPLETE(state) {
      return state.banksAutoComplete;
    },
  },
};

export default autocompleteModule;
