import api from "@/api";

const fundsModule = {
  namespaced: true,
  state: () => ({
    fundsList: [],
    fundDetales: {},
    fundBuyingList: [],
    fundSellingList: [],
    ownedShares: [],
  }),
  actions: {
    // GET Documents
    async GET_FUNDS({ commit }) {
      const data = await api.getAvalibleFunds();
      return new Promise((resolve) => {
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          resolve(false);
        } else {
          commit(
            "mutateState",
            { to: "FundsModule/fundsList", data: data.data },
            { root: true }
          );
          resolve(true);
        }
      });
    },
    async GET_FUND_DETALES({ commit }, payload) {
      let id = 0
      let notCheckCi = false
      if (typeof payload == "object") {
        id = payload.id
        notCheckCi = payload.not_check_ci
      } else
        id = payload
      const data = notCheckCi === true ? await api.getFundDetales({ data: { id: id, not_check_ci: notCheckCi } }) : await api.getFundDetales({ data: { id: id } });
      return new Promise((resolve) => {
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          resolve(false);
        } else {
          commit(
            "mutateState",
            { to: "FundsModule/fundDetales", data: data.model },
            { root: true }
          );
          resolve(true);
        }
      });
    },
    async GET_OWNED_SHARES({ commit }) {
      const data = await api.getOwnedShares();
      return new Promise((resolve) => {
        if (!data.success) {
          commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
          );
          resolve(false);
        } else {
          commit(
            "mutateState",
            { to: "FundsModule/ownedShares", data: data.data },
            { root: true }
          );
          resolve(true);
        }
      });
    },
    // async GET_FUNDS_BUYING_LIST({commit}) {
    //     const data = await api.getFundsBuying()
    //     return new Promise((resolve) => {
    //         if (!data.success) {
    //             commit('error', data.errors)
    //             resolve(false)
    //         } else {
    //             commit('set_toList', {to: "fundBuyingList", data: data.data})
    //             resolve(true)
    //         }
    //     })
    // },
    // async GET_FUNDS_SELLING_LIST({commit}) {
    //     const data = await api.getFundsSelling()
    //     return new Promise((resolve) => {
    //         if (!data.success) {
    //             commit('error', data.errors)
    //             resolve(false)
    //         } else {
    //             commit('set_toList', {to: "fundSellingList", data: data.data})
    //             resolve(true)
    //         }
    //     })
    // },
  },

  getters: {
    getFundsList(state) {
      return state.fundsList;
    },
    getFundDetales(state) {
      return state.fundDetales;
    },
    getOwnedShares(state) {
      return state.ownedShares;
    },
    getFundsBuyingList(state) {
      return state.fundsList?.filter(fund => fund.purchases?.length > 0)
    },
    // getFundsSellingArchive(state) {
    //   return state.fundsList?.filter((fund) => fund.sales.length > 0);
    // },
    getFundsSellingList(state, rootGetters, rootState) {
      return state.fundsList?.filter((fund) =>
        rootState.Sockets.socketVisibleSellShares.some((e) => e.id === fund.id) || fund.sales?.length > 0
      );
    },
  },
};

export default fundsModule;
