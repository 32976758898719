import api from "@/api";

const Messages = {
  namespaced: true,
  state: () => ({
    messages_doc: [],
    messages_pif: [],
    messages_info:[],
    messages: [
      {
        key: "message.id",
        date: 1634121801,
        file: "message.file",
        read: true,
        isImportant: false,
        text:
          "Уважаемый Иванов Иван Иванович!\n" +
          "УК «РВМ Капитал» сообщает о том, что в фонде Закрытый Паевой инвестиционный комбинированный фонд «РВМ Мичуринский» 2 планируется общее собрание владельцев инвестиционных паев. Подробности во вложении.",
        title: "Частичное и полное погашения паев",
      },
      {
        key: "message.id",
        date: 1634121801,
        file: "message.file",
        read: false,
        isImportant: false,
        text:
          "Уважаемый Иванов Иван Иванович!\n" +
          "УК «РВМ Капитал» сообщает о том, что в фонде Закрытый Паевой инвестиционный комбинированный фонд «РВМ Мичуринский» 2 планируется общее собрание владельцев инвестиционных паев. Подробности во вложении.",
        title: "Частичное и полное погашения паев",
      },
    ],
    isNewMessage: false
  }),
  actions: {
    async GET_NOTIFICATIONS({commit}) {
      const data = await api.getNotifications();
      if (!data.success) {
        commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
        );
      } else {
        const m_docs = data.data.filter(message => message.type_id === 1);
        commit(
            "mutateState",
            { to: "Messages/messages_doc", data: m_docs },
            { root: true }
        );
        const m_pifs = data.data.filter(message => message.type_id === 2);
        commit(
            "mutateState",
            { to: "Messages/messages_pif", data: m_pifs },
            { root: true }
        );
        const m_info = data.data.filter(message => message.type_id === 3);
        commit(
            "mutateState",
            { to: "Messages/messages_info", data: m_info },
            { root: true }
        );


        }
    },
    async SEND_NOTYFICATION({commit}, {message, files}) {
      const data = await api.sendNotification({data: {message: message}})
      if (!data.success) {
        commit(
            "mutateState",
            { to: "errorModule/error", data: data.errors },
            { root: true }
        );
      } else {
        for (let file of files) {
          let f = new FormData();
          f.append("file", file);
          f.append("file_type", 600);
          f.append("message_id", data.model?.id);
          const data1 = api.uploadFiles({ data: f });
          if (!data1.success) {
            commit(
                "mutateState",
                { to: "errorModule/error", data: data1.errors },
                { root: true }
            );
          }
        }
      }
    },
  },
//Тип уведомления (1 - Документы, 2 - ПИФы, 3 - Общая информация)
  getters: {
    MESSAGES_DOCS(state) {
      // return state.messages_doc.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((a.created_at > b.created_at) ? -1 : 0)).sort((a,b) => a.read-b.read);
      return state.messages_doc.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((a.created_at > b.created_at) ? -1 : 0));
    },
    MESSAGES_INFO(state) {
      // return state.messages_info.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((a.created_at > b.created_at) ? -1 : 0)).sort((a,b) => a.read-b.read);
      return state.messages_info.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((a.created_at > b.created_at) ? -1 : 0));
    },
    MESSAGES_PIF(state) {
      return state.messages_pif.sort((a,b) => (
          (a.fond.name.toUpperCase() < b.fond.name.toUpperCase()) ? 1 : ((a.fond.name.toUpperCase() > b.fond.name.toUpperCase()) ? -1 : 0)
          || (a.created_at < b.created_at) ? 1 : ((a.created_at > b.created_at) ? -1 : 0)
      ));
    },
    IS_NEW_MESSAGE(state) {
      return state.isNewMessage
    },
    MESSAGES_COUNT(state) {
      return state.messages_doc.filter(message => !message.read).length +
          state.messages_pif.filter(message => !message.read).length +
          state.messages_info.filter(message => !message.read).length;
    },
    NEW_DOCS(state) {
      return state.messages_doc.filter(message => !message.read).length > 0
    },
    NEW_INFO(state) {
      return state.messages_info.filter(message => !message.read).length > 0
    },
    NEW_PIF(state) {
      return state.messages_pif.filter(message => !message.read).length > 0
    },
    MESSAGES_ROUTE(state) {
      let path = "/cabinet/messages/documents"
      if (state.messages_doc.filter(message => !message.read).length === 0) {
        if (state.messages_pif.filter(message => !message.read).length > 0) {
          path = "/cabinet/messages/pif"
        } else if (state.messages_info.filter(message => !message.read).length > 0) {
          path = "/cabinet/messages/info"
        }
      }
      return path
    }
  },
};

export default Messages;
